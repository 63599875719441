// styles.scss ou ton fichier scss principal
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --rounded-box: 4px;
}

@media (prefers-reduced-motion: no-preference) {
  .btn {
      animation: none !important;
  }
}
